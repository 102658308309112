import React, { Suspense, lazy } from 'react';
import { store,persistor }from './store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import LazyLoader from './LazyLoader';
import { createGenerateClassName, StylesProvider } from '@material-ui/core/styles';

const generateClassName = createGenerateClassName({
  seed: 'selynk',
});

const LoadableComponent = lazy(() => import('./config/Navigation'));

class App extends React.Component {
  render() {
    return (
      <StylesProvider generateClassName={generateClassName}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Suspense fallback={<LazyLoader />}>
              <LoadableComponent />
            </Suspense>
          </PersistGate>
        </Provider>
      </StylesProvider>
    );
  }
}

export default App;